import './Navbar.scss';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import { IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import DialogGallery from '../DialogGallery/DialogGallery';

let toggleGalleryPublic;
class Navbar extends Component {
    constructor() {
        super();
        this.state = {
            drawlerOpenned: false,
            navbarHidden: false,
            transparent: false,
            galleryOpen: false,
        }
        toggleGalleryPublic = this.ToggleGallery.bind(this);

        let prevScrollpos = window.pageYOffset;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) == false) {
            this.state.transparent = true;
            window.onscroll = function () {
                let currentScrollPos = window.pageYOffset;

                //transparent navbar when on top
                if (window.pageYOffset < 100) {
                    if (this.state.transparent != true && this.state.navbarHidden != false) {
                        this.setState({
                            transparent: true
                        })
                    }
                }
                else {
                    if (this.state.transparent != false && this.state.navbarHidden == false) {
                        this.setState({
                            transparent: false
                        })
                    }
                }

                //hide navbar when scrolling down
                if (prevScrollpos > currentScrollPos) {
                    if (this.state.navbarHidden != false) {
                        this.setState({
                            navbarHidden: false
                        })
                    }
                } else {
                    if (this.state.navbarHidden != true) {
                        this.setState({
                            navbarHidden: true
                        })
                    }
                }
                prevScrollpos = currentScrollPos;
            }.bind(this);
        }

    }

    ToggleDrawler() {
        this.setState({
            drawlerOpenned: !this.state.drawlerOpenned,
        })
    }
    ToggleGallery() {
        this.setState({ galleryOpen: !this.state.galleryOpen });
    }

    render() {
        const drawerStyles = {
            paper: {
                background: "blue"
            }
        }
        let styles = {
            top: this.state.navbarHidden ? "-70px" : "0px",
            // display: this.state.navbarHidden ? "none" : "block"
        }
        if (this.state.transparent) styles.backgroundColor = "transparent";
        return (

            <div
                className="navbar"
                id="navbar"
                style={styles}>
                <DialogGallery open={this.state.galleryOpen} toggle={this.ToggleGallery.bind(this)} />
                <div className="container">

                    <span className="heading">
                        <a href="#landing-page"> Autoškola Elko</a>
                    </span>
                    <span className="navigation">
                        <span className="menuButtons">
                            <Button color="inherit" href="#about-us">O nás</Button>
                            <Button color="inherit" href="#services" >Služby</Button>
                            <Button color="inherit" href="#scrollto-information" >Informace</Button>
                            <Button color="inherit" onClick={this.ToggleGallery.bind(this)} >Galerie</Button>
                            <Button color="inherit" href="#contacts" >Kontakt</Button>
                        </span>
                        <span className="menuButton">
                            <IconButton onClick={this.ToggleDrawler.bind(this)} color="inherit">
                                <Menu />
                            </IconButton>
                        </span>
                    </span>
                </div>
                <Drawer
                    anchor="top"
                    open={this.state.drawlerOpenned}
                    onClose={this.ToggleDrawler.bind(this)}
                >
                    <List>
                        <ListItem button component="a" href="#about-us" onClick={this.ToggleDrawler.bind(this)}>
                            <ListItemText>
                                O nás
                             </ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#services" onClick={this.ToggleDrawler.bind(this)}>
                            <ListItemText>
                                Služby
                             </ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#scrollto-information" onClick={this.ToggleDrawler.bind(this)}>
                            <ListItemText>
                                Informace
                             </ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#information" onClick={
                            () => {
                                this.ToggleDrawler();
                                this.ToggleGallery();
                            }
                        }>
                            <ListItemText>
                                Galerie
                             </ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#contacts" onClick={this.ToggleDrawler.bind(this)}>
                            <ListItemText>
                                Kontakt
                             </ListItemText>
                        </ListItem>
                    </List>
                </Drawer>
            </div>

        );
    }
}


export default Navbar;
export { toggleGalleryPublic };